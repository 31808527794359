<!-- eslint-disable max-len -->
<template>
  <div>
    <!-- Carousel Start -->
  <div class="container-fluid p-0 pb-5 wow fadeIn" data-wow-delay="0.1s">
      <div class="owl-carousel header-carousel position-relative">
        <div class="owl-carousel-item position-relative"
          data-dot="<img src='../../assets/img/banner2.png'>">
            <img class="img-fluid" src="../../assets/img/banner2.webp" alt="">
            <div class="owl-carousel-inner">
                <div class="container">
                    <div class="row justify-content-start">
                        <div class="col-10 col-lg-8">
                            <h1 class="display-1 text-white animated slideInDown">
                              {{ translate('bannerTitle') }}</h1>
                            <p class="fs-5 fw-medium text-white mb-4 pb-3">
                                {{ translate('bannerDescription') }}</p>
                            <a href="" class="btn btn-primary py-3 px-5 animated slideInLeft">
                                {{ translate('contactUs') }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
  </div>
  <!-- Carousel End -->

  <!-- Facts Start -->
  <div class="container-xxl py-5">
      <div class="container pt-5">
          <div class="row g-4">
              <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                  <div class="fact-item text-center bg-light h-100 p-5 pt-0">
                      <div class="fact-icon">
                          <img src="../../assets/img/arch-mini.png" alt="Icon">
                      </div>
                      <h3 class="mb-3">{{ translate('service1') }}</h3>
                      <p class="mb-0">
                      </p>
                  </div>
              </div>
              <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                  <div class="fact-item text-center bg-light h-100 p-5 pt-0">
                      <div class="fact-icon">
                          <img src="../../assets/img/bew-mini.png" alt="Icon">
                      </div>
                      <h3 class="mb-3">{{ translate('service2') }}</h3>
                      <p class="mb-0"></p>
                  </div>
              </div>
              <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                  <div class="fact-item text-center bg-light h-100 p-5 pt-0">
                      <div class="fact-icon">
                          <img src="../../assets/img/tr-mini.png" alt="Icon">
                      </div>
                      <h3 class="mb-3">{{ translate('service3') }}</h3>
                      <p class="mb-0"></p>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!-- Facts End -->

    <!-- Appointment Start -->
    <div id="contact" class="container-xxl py-5">
        <div class="container">
            <div class="row g-5">
                <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <h4 class="section-title">{{ translate('contact') }}</h4>
                    <h1 class="display-5 mb-4">{{ translate('contactSectionTitle') }}</h1>
                    <p class="mb-4">{{ translate('contactSectionDescription') }}</p>
                    <div class="row g-4">
                        <div class="col-12">
                            <div class="d-flex">
                                <div class="d-flex flex-shrink-0 align-items-center justify-content-center bg-light" style="width: 65px; height: 65px;">
                                    <i class="fa fa-2x fa-phone-alt text-primary"></i>
                                </div>
                                <div class="ms-4">
                                    <p class="mb-2">{{ translate('callUs') }}</p>
                                    <h3 class="mb-0">+38760 340 0186</h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="d-flex">
                                <div class="d-flex flex-shrink-0 align-items-center justify-content-center bg-light" style="width: 65px; height: 65px;">
                                    <i class="fa fa-2x fa-envelope-open text-primary"></i>
                                </div>
                                <div class="ms-4">
                                    <p class="mb-2">{{ translate('sendEmail') }}</p>
                                    <h3 class="mb-0">info@plantec.ba</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="row g-3">
                        <div class="col-12 col-sm-6">
                            <input type="text" class="form-control" v-model="firstName" :placeholder="translate('yourFirstName')" style="height: 55px;">
                        </div>
                        <div class="col-12 col-sm-6">
                            <input type="text" class="form-control" v-model="lastName" :placeholder="translate('yourLastName')" style="height: 55px;">
                        </div>
                        <div class="col-12 col-sm-6">
                            <input type="email" class="form-control" v-model="email" :placeholder="translate('yourEmail')" style="height: 55px;">
                        </div>
                        <div class="col-12 col-sm-6">
                            <input type="text" class="form-control" v-model="phone" :placeholder="translate('yourPhone')" style="height: 55px;">
                        </div>
                        <div class="col-12">
                            <textarea class="form-control" rows="5" v-model="message" :placeholder="translate('yourMessage')"></textarea>
                        </div>
                        <div class="col-12">
                            <button class="btn btn-primary w-100 py-3" :disabled="!validForm" @click.stop="sendEmail" type="submit">{{ translate('sendMessage') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Appointment End -->
  </div>

</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: 'Home',
  components: {

  },
  data() {
    return {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      message: '',
    };
  },
  mounted() {
  },
  computed: {
    validForm() {
      return this.firstName && this.lastName && this.phone && this.email && this.message;
    },
  },
  methods: {
    sendEmail() {
      const templateParams = {
        from_name: `${this.firstName} ${this.lastName}`,
        from_email: this.email,
        from_phone: this.phone,
        message: this.message,
      };

      emailjs.send('service_stp3mp8', 'template_3w0rmkv', templateParams, 'G-x5xKIijY2n8t-S7')
        .then(() => {
          this.$toast.success(this.translate('successMessage'));
        }, () => {
          this.$toast.error(this.translate('errorMessage'));
        });

      // Reset form fields
      this.firstName = '';
      this.lastName = '';
      this.phone = '';
      this.email = '';
      this.message = '';
    },
  },
};
</script>
<style scoped>
</style>
